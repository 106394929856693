import React from "react";
import { router } from "dva";

import { Affix, Menu, Input, Popover, Modal, Row, Col } from "antd";
import { template, setLocalStorage, getLocalStorage } from "@reco-m/core";
import { changeUrlParams, ViewComponent, mtaH5Click, statisticsEvent, getParkCode } from "@reco-w/core-ui";

import { Namespaces, headerModel } from "@reco-w/layout-models";
import { ArticleCatalogueEnum, informationCenterCode } from "@reco-w/article-models";
import { SecondTypeEnum } from "@reco-w/second-models";

export namespace PageHeader {
    const SubMenu = Menu.SubMenu;

    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}

    export interface IState extends ViewComponent.IState, headerModel.StateType {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        componentDidMount() {
            this.dispatch({
                type: "initPage",
                inputData: { radioSelect: getLocalStorage("parkId") },
                judgeParkCode: this.parkcode,
                // error: e => Modal.error({ title: "消息提醒", content: e })
            });

            this.dispatch({
                type: "getArticleList",
                data: { catalogueCode: "SJBZ" },
                callback: (list) => {
                    if (list && list.items && list.items.length > 0) {
                        const sjbzArticle = list.items[0];
                        this.dispatch({ type: "input", data: { sjbzArticle: sjbzArticle } });
                    }
                },
            });

            $(".search-box input")
                .focus(() => {
                    $(".search-box").addClass("active");
                })
                .blur(() => {
                    $(".search-box").removeClass("active");
                });

            this.dispatch({ type: "input", data: { mode: "horizontal", headerID: false } });
            // 手机端
            this.menuState();
            $(window).resize(() => {
                this.menuState();
            });
        }

        componentReceiveProps(nextProps: P) {
            let isLogin =
                (nextProps.location?.search?.includes("isLogin=") && !this.props.location?.search?.includes("isLogin=")) ||
                (this.props.location?.search?.includes("isLogin=") && !nextProps.location?.search?.includes("isLogin="));

            if (isLogin) {
                this.dispatch({ type: "getCertify" });
            }
        }

        /**
         * 切换园区
         * @param park
         */
        changePark(park: any) {
            if (park) {
                mtaH5Click(statisticsEvent.parkSwitch);
                setLocalStorage("parkId", park.value);
                setLocalStorage("parkName", park.label);
                setLocalStorage("parkCode", park.code);
                setLocalStorage("selectedMenu", "/home"); // 设置被选中的菜单为home
                this.dispatch({
                    type: "input",
                    data: { radioSelect: park.value, parkName: park.label, parkId: park.value },
                });
                if (this.isAuth()) {
                    this.dispatch({ type: "getCheckInDetail" });
                    this.dispatch({ type: "memberCommon/cleanMemberInfo" }); // 切换园区清除原来认证信息
                    this.dispatch({ type: "memberCommon/cleanLoyalty" }); // 切换园区清除原来积分信息
                }
            }
        }

        /**
         * 渲染登录按钮
         * @returns
         */
        renderLogin(): React.ReactNode {
            const { state } = this.props,
                user = state!.user;

            return this.isAuth() ? (
                <div className="user-info">
                    <router.Link
                        to={`/${getParkCode()}/my`}
                        onClick={() => {
                            setLocalStorage("selectedMenu", "/my");
                            setLocalStorage("mySelectedMenu", "/my");
                        }}
                    >
                        {(user && user.nickName) || "请设置昵称"}
                    </router.Link>
                    <em>丨</em>
                    <router.Link
                        to={`/${getParkCode()}/login`}
                        onClick={() =>
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/${getParkCode()}/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            })
                        }
                    >
                        注销
                    </router.Link>
                </div>
            ) : (
                <div className="user-info">
                    <router.Link to={`/${getParkCode()}/login`}>
                        <i className="icon iconsuo margin-right-xs" />
                        登录
                    </router.Link>
                    <em>丨</em>
                    <router.Link to={`/${getParkCode()}/login/register`}>
                        <i className="icon iconyonghu margin-right-xs" />
                        注册
                    </router.Link>
                </div>
            );
        }

        /**
         * 渲染最上栏
         * @returns
         */
        renderParkAndLogin(): React.ReactNode {
            const { state } = this.props,
                parkNames = state!.parkNames || [],
                radioSelect = getLocalStorage("parkId") || state!.radioSelect,
                parkName = getLocalStorage("parkName") || state!.parkName;

            const content = (
                <div className="park-select">
                    <div className="park-select-box">
                        {parkNames &&
                            parkNames.length > 0 &&
                            parkNames.map((item, i) => (
                                <router.Link key={"park" + i} className="park-radio-box" to={`/${item.code}`} onClick={() => this.changePark(item)}>
                                    <span className={"park-radio" + (item.value === radioSelect ? " park-radio-checked" : "")}>
                                        <span className="park-radio-input"></span>
                                        <span className={"park-radio-inner" + (item.value === radioSelect ? " park-radio-inner-checked" : "")}></span>
                                    </span>
                                    <span className="park-radio-text">{item.label}</span>
                                </router.Link>
                            ))}
                    </div>
                </div>
            );

            return (
                <div className="nav">
                    <div className="container">
                        <div className="pull-left">
                            <i className="icon iconmarker size-14 mr5"></i>
                            当前园区：{parkName}
                            {parkNames.length > 0 && (
                                <Popover placement="bottomLeft" content={content} title="选择园区" trigger="hover">
                                    <a>【切换】</a>
                                </Popover>
                            )}
                        </div>
                        <div className="pull-right">{this.renderLogin()}</div>
                    </div>
                </div>
            );
        }

        getSelectedKeys(menuKey) {
            if (menuKey) return [menuKey];
            const selectedMenuText = getLocalStorage("selectedMenu"),
                selectedMenus = selectedMenuText && selectedMenuText.split("?");
            let selectedMenu = selectedMenus && selectedMenus.length > 0 && selectedMenus[0];

            return [selectedMenu || ""];
        }

        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            const { state } = this.props,
                mode = state!.mode;
            return (
                <>
                    {mode === "inline" ? (
                        <Menu.Item key={key}>
                            <router.Link
                                to={link}
                                className={flag ? "size-16 white-color" : ""}
                                onClick={() => {
                                    mtaH5Click(event);
                                }}
                            >
                                {title}
                            </router.Link>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key={key}>
                            <router.Link
                                to={link}
                                className={flag ? "size-18" : ""}
                                onClick={() => {
                                    mtaH5Click(event);
                                }}
                            >
                                {title}
                            </router.Link>
                        </Menu.Item>
                    )}
                </>
            );
        }

        getSecondPath(code, type?, parentMenuName?) {
            const parkCode = getParkCode();
            return `/${parkCode}/second/${code}?type=${type}&parentName=${parentMenuName}`;
        }

        getArticlePath(code) {
            const parkCode = getParkCode();
            let val: any = "";
            informationCenterCode.forEach((e) => {
                if (code === e.code) {
                    val = e.value;
                }
            });
            return `/${parkCode}/article/${code}?tagValue=${val}`;
        }

        /**
         * 渲染目录栏
         * @returns
         */
        renderMenu(): React.ReactNode {
            const { state } = this.props,
                menuKey = state!.menuKey,
                sjbzArticle = state!.sjbzArticle,
                parkCode = getParkCode(),
                selectedKeys: any[] = this.getSelectedKeys(menuKey),
                mode = state!.mode;
            return (
                <>
                    {mode === "inline" ? (
                        <Menu selectedKeys={selectedKeys} mode={mode} theme="dark" className="index-header">
                            {this.renderMenuItem("/home", `/${parkCode}`, statisticsEvent.navigationBarHome, "首页", true)}
                            <SubMenu title={<span className="submenu-title-wrapper size-16">组织机构</span>}>
                                {this.renderMenuItem(
                                    "/article/SJBZ",
                                    sjbzArticle ? `/${parkCode}/article/${sjbzArticle.id}` : `/${parkCode}/article/SJBZ`,
                                    statisticsEvent.navigationBarSecretary,
                                    "书记班子"
                                )}
                                {this.renderMenuItem(
                                    `/second/${ArticleCatalogueEnum.partroomfunction}`,
                                    `${this.getSecondPath(ArticleCatalogueEnum.partroomfunction, SecondTypeEnum.toDetail, "组织机构")}`,
                                    statisticsEvent.navigationBarDepartment,
                                    "部室职能"
                                )}
                            </SubMenu>
                            {this.renderMenuItem("/article/TZTG", `/${parkCode}/article/TZTG`, statisticsEvent.navigationBarNotification, "通知公告", true)}
                            <SubMenu title={<span className="submenu-title-wrapper size-16">工作动态</span>}>
                                {this.renderMenuItem(
                                    "/organization/secretary",
                                    `/${parkCode}/article/${ArticleCatalogueEnum.workNews}`,
                                    statisticsEvent.navigationBarWorkNews,
                                    "工作要讯"
                                )}
                                {this.renderMenuItem(
                                    `/second/${ArticleCatalogueEnum.grassrootsDynamics}`,
                                    `/${parkCode}/second/${ArticleCatalogueEnum.grassrootsDynamics}`,
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "基层动态"
                                )}
                            </SubMenu>
                            {this.renderMenuItem(
                                `/article/${ArticleCatalogueEnum.mediafocus}`,
                                this.getArticlePath(ArticleCatalogueEnum.mediafocus),
                                statisticsEvent.navigationBarNotification,
                                "媒体聚焦",
                                true
                            )}
                            <SubMenu title={<span className="submenu-title-wrapper size-16">资料中心</span>}>
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.daShiJiHeRongYu}`,
                                    this.getArticlePath(ArticleCatalogueEnum.daShiJiHeRongYu),
                                    statisticsEvent.navigationBarWorkNews,
                                    "大事记和荣誉"
                                )}
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.wangShangTuanXiao}`,
                                    this.getArticlePath(ArticleCatalogueEnum.wangShangTuanXiao),
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "网上团校"
                                )}
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.weiXinGongZhongHao}`,
                                    this.getArticlePath(ArticleCatalogueEnum.weiXinGongZhongHao),
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "浦东青年微信公众号热文"
                                )}
                            </SubMenu>
                        </Menu>
                    ) : (
                        <Menu selectedKeys={selectedKeys} mode={mode} theme="dark" className="index-header">
                            {this.renderMenuItem("/home", `/${parkCode}`, statisticsEvent.navigationBarHome, "首页", true)}
                            <SubMenu title={<span className="submenu-title-wrapper size-18">组织机构</span>}>
                                {this.renderMenuItem(
                                    "/organization/secretary",
                                    sjbzArticle ? `/${parkCode}/article/${sjbzArticle.id}` : `/${parkCode}/article/SJBZ`,
                                    statisticsEvent.navigationBarSecretary,
                                    "书记班子"
                                )}
                                {this.renderMenuItem(
                                    `/second/${ArticleCatalogueEnum.partroomfunction}`,
                                    `${this.getSecondPath(ArticleCatalogueEnum.partroomfunction, SecondTypeEnum.toDetail, "组织机构")}`,
                                    statisticsEvent.navigationBarDepartment,
                                    "部室职能"
                                )}
                            </SubMenu>
                            {this.renderMenuItem("/article/TZTG", `/${parkCode}/article/TZTG`, statisticsEvent.navigationBarNotification, "通知公告", true)}
                            <SubMenu title={<span className="submenu-title-wrapper size-18">工作动态</span>}>
                                {this.renderMenuItem(
                                    "/organization/secretary",
                                    `/${parkCode}/article/${ArticleCatalogueEnum.workNews}`,
                                    statisticsEvent.navigationBarWorkNews,
                                    "工作要讯"
                                )}
                                {this.renderMenuItem(
                                    `/second/${ArticleCatalogueEnum.grassrootsDynamics}`,
                                    `/${parkCode}/second/${ArticleCatalogueEnum.grassrootsDynamics}`,
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "基层动态"
                                )}
                            </SubMenu>
                            {this.renderMenuItem(
                                `/article/${ArticleCatalogueEnum.mediafocus}`,
                                this.getArticlePath(ArticleCatalogueEnum.mediafocus),
                                statisticsEvent.navigationBarNotification,
                                "媒体聚焦"
                            )}
                            <SubMenu title={<span className="submenu-title-wrapper size-18">资料中心</span>}>
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.daShiJiHeRongYu}`,
                                    this.getArticlePath(ArticleCatalogueEnum.daShiJiHeRongYu),
                                    statisticsEvent.navigationBarWorkNews,
                                    "大事记和荣誉"
                                )}
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.wangShangTuanXiao}`,
                                    this.getArticlePath(ArticleCatalogueEnum.wangShangTuanXiao),
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "网上团校"
                                )}
                                {this.renderMenuItem(
                                    `/article/${ArticleCatalogueEnum.weiXinGongZhongHao}`,
                                    this.getArticlePath(ArticleCatalogueEnum.weiXinGongZhongHao),
                                    statisticsEvent.navigationBarGrassrootsDynamics,
                                    "浦东青年微信公众号热文"
                                )}
                            </SubMenu>
                        </Menu>
                    )}
                </>
            );
        }

        /**
         * 关键字搜索
         * @param value
         */
        searchKey(value: string) {
            console.log(value, value && value.trim());
            if (value && value.trim()) {
                mtaH5Click(statisticsEvent.globalSearch);
                this.goTo(changeUrlParams(`/${getParkCode()}/heardsearch`, { gk: value }));
            } else {
                if (location.pathname.indexOf("heardsearch") > -1) this.goTo(`/${getParkCode()}`);
                else window.location.reload();
            }
        }

        /**
         * 渲染搜索栏
         * @returns
         */
        renderSearch(): React.ReactNode {
            const { state } = this.props,
                searchKey = state!.searchKey;
            return (
                <Input.Search
                    placeholder="输入关键字查询"
                    value={searchKey}
                    // suffix={<i className="icon iconsearch1 hand" onClick={() => this.searchKey(searchKey)}></i>}
                    className="search-box"
                    enterButton={"搜索"}
                    onSearch={(e) => this.searchKey(e) as any}
                    onChange={(e) => this.dispatch({ type: "input", data: { searchKey: e.target.value } })}
                />
            );
        }

        changeMenu(headerID) {
            this.dispatch({ type: "input", data: { headerID: !headerID } });
        }

        menuState() {
            let wh: any = $(window).width();
            if (wh < 1200) {
                this.dispatch({ type: "input", data: { mode: "inline" } });
            } else {
                this.dispatch({ type: "input", data: { mode: "horizontal" } });
            }
        }

        render(): React.ReactNode {
            const { state } = this.props,
                mode = state!.mode,
                headerID = state!.headerID;
            return (
                <>
                    {mode === "inline" ? (
                        <Affix offsetTop={0} className="header-affix">
                            <div className={headerID ? "second-header open" : "second-header"}>
                                <div className="header-content">
                                    <div className="header-mobile-menu" onClick={() => this.changeMenu(headerID)}>
                                        <em />
                                        <em />
                                        <em />
                                    </div>
                                    <div className="container">
                                        <Row className="header-row" align="middle">
                                            <Col span={12}>
                                                <div className="logo-m">
                                                    <img src={"assets/images/pudong/logo-m.png"} alt={"logo"} />
                                                    <span>
                                                        共青团上海市
                                                        <br />
                                                        浦东新区委员会
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={12} className="ant-text-right head-info">
                                                {this.renderLogin()}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="nav phone">
                                    <div className="container">{this.renderMenu()}</div>
                                </div>
                            </div>
                        </Affix>
                    ) : (
                        <div className="second-header">
                            <div className="header-content">
                                <div className="container">
                                    <Row>
                                        <Col span={12}>
                                            <div className="logo">
                                                <img src={"assets/images/pudong/logo.png"} alt={"logo"} />
                                            </div>
                                        </Col>
                                        <Col span={12} className="ant-text-right">
                                            {this.renderLogin()}
                                            <div className="header-search">{this.renderSearch()}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <Affix offsetTop={0} className="header-affix">
                                <div className="nav">
                                    <div className="container">{this.renderMenu()}</div>
                                </div>
                            </Affix>
                        </div>
                    )}
                </>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
